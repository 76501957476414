
import img1 from '../assets/img1.jpg'
import img2 from '../assets/img2.jpg'
import img3 from '../assets/img3.jpg'
import img4 from '../assets/img4.jpg'
import img5 from '../assets/img5.jpg'
import img6 from '../assets/img6.jpg'
import makeup from '../assets/makeup.jpg'

const SData = [
    {
        no: 1,
        title: "Hair Cut",
        description: "Have Professional hair dression to cut or design your hair",
        imgsrc: img1
        
    },
    {
        no: 2,
        title: "Hair Color",
        description: "Have Professional hair dression to cut or design your hair",
        imgsrc: img3
    },
    {
        no: 3,
        title: " Hair Treatment",
        description: "Have Professional hair dression to cut or design your hair",
        imgsrc: img2
    },
    {
        no: 4,
        title: " Hair Styling",
        description: "Have Professional hair dression to cut or design your hair",
        imgsrc: img4
    },
    {
        no: 5,
        title: "Makeup",
        description: "Have Professional hair dression to cut or design your hair",
        imgsrc: img5

    },
    {
        no: 6,
        title: " Bridal Makeup",
        description: "Have Professional hair dression to cut or design your hair",
        imgsrc: makeup
    }


]

export default SData;