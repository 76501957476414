const PData = [
    {
        title: "Hair Cuts",
        description: "Have Professional hair dresser to cut or design your hair",
        design1:"Men's cut  $20",
        design2:"Short hair no Blow-dry $65 ",
        design3:"Style cut & Blow-dry $76",
        design4:"Clippers $28",
        design5:" Children's (under 8)	$26 ",
        design6:" Teenage cut (under 15) $50 ",
        design7:" Fringe	$15 ",
        design8:" Extensions	POA ",

        
    },
    {
        title: "Hair Color And Treatment",
        description: "Have Professional person to color and make a tratment for your hair",
        design1:" Re-growth tint	$77",
        design2:" All-over short	$91",
        design3:" All-over long (extra thick +$15)	$99",
        design4:" Toner	$32-42",
        design5:" 1/2 Head Foils	$110 ",
        design6:" Full Head Foils	$145",
        design7:" Keratin Smoothing	from $290 ",
        design8:"Express Keratin	from $180 ",
    },
    {
        title: "Hair Styling and Makeup",
        description: "Have Professional beautician to style your hair and for your makeup",
        design1:"Blow-dry Short	$46",
        design2:"Shoulder length	$56",
        design3:"Below Shoulder	$64",
        design4:" GHD Curls	$46",
        design5:"GHD Straighten	$35 ",
        design6:"Up-style	$65-$95 ",
        design7:" Normal Makeup from $60 ",
        design8:"Bridal Makeup	from $100  ",
    }


]

export default PData;