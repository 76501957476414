import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css'

const Contact = () => {
  const [user_firstName, setUser_firstName] = useState("");
  const [user_lastName, setUser_lastName] = useState("");
  const [user_pho, setUser_Pho] = useState('');
  const [user_email, setUser_Email] = useState("");
  const [user_message, setUser_Message] = useState("");
 
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoError, setPhoError] = useState("");
  const [emailError, setEmailError] = useState("");

 
  const form = useRef();

  const setFirstNameValue = (e) => {
    setUser_firstName(e.target.value);
  };
  const setLastNameValue = (e) => {
    setUser_lastName(e.target.value);
  };
  const setPhoValue = (e)=>{
    setUser_Pho(e.target.value)
  }
  const setEmailValue = (e) => {
    setUser_Email(e.target.value);
  };
  const setMessageValue = (e) => {
    setUser_Message(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validFirstName = validateFirstName(setFirstNameError, user_firstName);
    if (validFirstName){
      setFirstNameError("");
    }
    const validLastName = validateLastName(setLastNameError, user_lastName);
    if (validLastName){
      setLastNameError("");
    }
    const validPho = validatePho(setPhoError, user_pho);
    if (validPho){
      setPhoError("");
    }
    const validMail = validateEmail(setEmailError, user_email);
    if (validMail) {
      setEmailError(""); 
    }
   
    emailjs.sendForm( 
      process.env.REACT_APP_SERVICE_ID, 
      process.env.REACT_APP_TEMPLATE_ID, 
      form.current, 
      process.env.REACT_APP_PUBLIC_KEY
      )
      .then((result) => {
        console.log(result.text, result.status);
          setUser_firstName('');
            setUser_lastName('');
             setUser_Pho('');
               setUser_Email('');
               setUser_Message('');
      }, (error) => {
        console.log(error.text);
        
      });
  };

// function to validate name
  const  validateFirstName = (setFirstNameError, user_firstName)=>{
    if (user_firstName === "") {
      setFirstNameError("name cannot be blank");
      return;
    }
    if (user_firstName.trim().length < 3 ) {
      setFirstNameError("name cannot be less than 3 letter");
      return;
    }
    if (user_firstName.trim().length > 10 ) {
      setFirstNameError("name cannot be more than 10 letter");
      return;
    }
    return true;
  };
    // function to validate name
    const  validateLastName = (setLastNameError, user_lastName)=>{
      if (user_lastName === "") {
        setLastNameError("name cannot be blank");
        return;
      }
      if (user_lastName.trim().length < 3 ) {
        setLastNameError("name cannot be less than 3 letter");
        return;
      }
      if (user_lastName.trim().length > 10 ) {
        setLastNameError("name cannot be more than 10 letter");
        return;
      }
      return true;
    };

  const  validatePho = (setPhoError, user_pho)=>{
    if (user_pho === "") {
      setPhoError("phone no cannot be blank");
      return;
    }
    if (user_pho.trim().length < 10 ) {
      setPhoError("phone no cannot be less than 10 digit");
      return;
    }
    if (user_pho.trim().length > 10 ) {
      setPhoError("phone no cannot be more than 10 digit");
      return;
    }
    return true;
};
  // function to validate email
  const validateEmail = (setEmailError, user_email) => {
    if (user_email === "") {
      setEmailError("mail cannot be blank");
      return;
    }
    if (!/^[[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user_email)) {
      setEmailError("email is invalid");
      return;
    }
    return true;
  };
 
  return (
    <div> 
      <div className="contact-section">
        <h2 className='fade-in'> <span> OUR </span> Contact Details </h2>
              <div className=" contact-info">
              
                    <div className="contact-card">
                          <h6>Would you like to visit us</h6>
                          <h3>Our Address <i class="fa-solid fa-location-dot"></i></h3>
                          <p> 217 Hazel Glen Drive, Doreen, VIC</p>
                    </div>
                    <div className="contact-card">
                          <h6>Would you like to call us</h6>
                          <h3>Our phone no <i class="fa fa-phone-square" aria-hidden="true"></i></h3>
                          <p> 0469594670</p>
                      </div>
                      
                      <div className="contact-card ">
                          <h6>Would you like to Email us </h6>
                          <h3>Our Email <i class="fa-solid fa-envelope"></i></h3>
                          <p>admin@doreenbeautyparlour.com</p>
                      </div>
                </div>

                <div className="form-section">
                    <h3 className="contact-title">Get In Touch</h3>
                    <form ref={form} 
                    onSubmit={handleSubmit} 
                    className="contact-Form"  to="/thankyou">
                          <div className="name">
                              <input type="text" 
                              name='user_firstName' 
                              value={user_firstName} 
                              placeholder="First Name" 
                              onChange={setFirstNameValue}
                              required/>
                              {firstNameError && <p style={{ color: "red" }}>{firstNameError}</p>}

                              <input type="text" 
                              name="user_lastName" 
                              value={user_lastName} 
                              placeholder="Last Name" 
                              onChange={setLastNameValue}
                              required/> 
                                {lastNameError && <p style={{ color: "red" }}>{lastNameError}</p>}
                          </div>

                          <div className="phone-email">
                              <input type="number" 
                              name="user_pho" 
                              value={user_pho} 
                              placeholder="Phone No" 
                              onChange={setPhoValue}
                              required/>
                            
                            {phoError && <p style={{ color: "red" }}>{phoError}</p>}

                              <input type="email" 
                              name="user_email" 
                              value={user_email} 
                              placeholder="Email" 
                              onChange={setEmailValue}
                              required/>
                              {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                          </div>
                          <div className="message">
                          
                            <textarea name="user_message" 
                            id="" cols="52" rows="5" 
                            value={user_message} 
                            onChange={setMessageValue} 
                            placeholder="Your Message"></textarea>
                          </div>
                          <input type="submit"  className="submitBtn" value="Send" />
                        </form>
                    </div>
                <p>Services available with an appointmnet only</p>
         </div>
         </div>
        )
}

export default Contact